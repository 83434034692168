import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model";
import { IconPath } from '../../common/icon';
import React from 'react';
import bgImage from '../../assets/bgimagehome.png';

export interface HomeProps {}

export const PrivacyPolicy: React.FC<HomeProps> = (props: HomeProps) => {

  return (
    <div className="xl:w-[1200px] mx-auto w-full align-left text-white">
      <div className="relative px-6 lg:px-8 text-left text-gray-900 h-60vh flex items-left h-full">
        <div className="mx-auto py-8 sm:py-4 lg:py-4">
          <div className="text-left w-full">
            <h1 className="text-l font-bold tracking-tight sm:text-xl">
              Guac Privacy & Policy
            </h1>
            <p className="mt-6 text-sm leading-8">
              Guac is committed to maintaining the confidentiality, integrity, and security of any personal information about our users. This Privacy Policy explains how we protect personal and other information provided through our website www.guacapp.com (the “Website”) and our mobile application (the “App”), and how we collect, use, and share that information in connection with our services offered through either of these (the “Service”).

              <br /><br />
              <strong>Personal Information</strong><br />
              “Personal information” for purposes of this Policy means information that identifies you, such as your name, address, social security number, driver’s license ID number, passport number, phone number, fax number, or email address. Below, we discuss when and why we collect different types of personal information.

              Guac implements its privacy and security standards to guard against identity theft and provide security for your personal information. We regularly review our privacy and security policies and adapt them as necessary to deal with new challenges.

              Guac updates this Privacy Policy periodically. The date of the last revision is 10/2/17. Changes take effect immediately upon posting.

              If you have questions, comments, concerns, or feedback regarding this Privacy Policy or any other privacy or security concerns, send an email to support@Guacapp.com.

              <br /><br />
              <strong>Your Privacy is Not for Sale</strong><br />
              We take your privacy seriously. We do not sell or rent your personal information to anyone, for any reason.

              <br /><br />
              <strong>You Can Delete Your Data Anytime by Deleting Your Account</strong><br />
              You may request that we delete your account for the Service. If you do so, your Personal Information will be permanently expunged from our servers and further access to your account will not be possible. We will also promptly disconnect any connection we had established to your Account Information (see below) and delete all account credentials.

              However, portions of your Personal Information, in de-identified and aggregate forms, may remain on our system indefinitely. We reserve the right to use this aggregate data to improve our service and for other lawful purposes, as discussed below.

              Also, please note that we also may need to store your data as required by federal, state, or local law, even if you request that we delete it.

              To request deletion of your account, email support@guacapp.com, requesting a deletion of your account. Merely deleting the app from your device does not constitute a request to delete your account.

              <br /><br />
              <strong>Your Security</strong><br />
              <strong>We Are Committed To Keeping Your Personal Information Secure</strong><br />
              The security of your personal information is important to us. We utilize physical, technical, and organizational security measures to protect against loss, misuse, and alteration of information under our control. We offer industry-standard practices and security measures to safeguard and secure the personal information we collect. However, no method of transmission over the Internet or method of electronic storage is 100% secure.

              We use a combination of firewall barriers, encryption techniques, and authentication procedures, among others, to maintain the security of your online session and to protect Guac accounts and systems from unauthorized access.

              <br /><br />
              <strong>Guac Ensures Secure Communications with Encryption</strong><br />
              All communications between your device and Guac are encrypted. This enables client and server applications to communicate in a way that is designed to prevent eavesdropping, tampering, and message forgery.

              <br /><br />
              <strong>It is your responsibility to keep your Login ID, Password, Mobile Device, and Email Account Confidential</strong><br />
              We maintain strict rules to help prevent others from guessing your password. We recommend changing your password periodically. Your password must be at least 8 characters in length.

              <br /><br />
              <strong>Collecting Your Personal Information</strong><br />
              Certain areas and features of our Website or Service are available to you without registration or the need to provide us with any personal information. However, other features of the Website or Service may require registration, which involves provision of your name, email address, mobile phone number, and a password.

              When you use certain features of the Service, we collect a variety of other personal information from you. This may include your name, address, phone number, email address, social security number, driver’s license number, state ID card number, passport number, utility bill information, and login information for online financial and other accounts. In some cases, we will collect scanned copies of such information.

              <br /><br />
              <strong>Keep Your Personal Information Up to Date to Gain Full Access</strong><br />
              It is your responsibility to keep your personal information up to date by updating your profile at www.guacapp.com. We are not responsible for costs or other issues arising from you not keeping your personal information up to date.

              In order to benefit from the full functionality of the Service, you must provide your third-party account logins to allow Guac and our partners to access your account data at those financial institutions for your use. From time to time, we may request other personal information to provide you with other benefits of the Service.

              <br /><br />
              <strong>Forward-to-a-Friend</strong><br />
              We offer a feature that allows you to share a message with a friend encouraging him or her to use Guac. If you choose to use this feature, we will ask you for the recipient’s name and email address.

              <br /><br />
              <strong>We May Use Your Personal Information</strong><br />
              Guac and its affiliated companies use your personal information only as follows:
              <ul className="list-disc ml-6">
                <li>To analyze site usage and improve the Service;</li>
                <li>To deliver to you any administrative notices, alerts, and communications relevant to your use of the Service;</li>
                <li>To market products and services that we believe may be of interest to you (you may opt-out);</li>
                <li>For market research, project planning, troubleshooting problems, detecting and protecting against error, fraud, or other criminal activity;</li>
                <li>To enforce our Terms of Use.</li>
              </ul>

              <br /><br />
              <strong>How and Why We Share Your Personal Information</strong><br />
              Guac and its affiliated companies use third-party service providers to help manage and improve the Service. These service providers may collect and/or use your personal or other information to assist us. For example, we use third parties to host features of our Service and to help us implement email communications.

              We may also transmit your personal information to our banking partners to meet legal requirements. Additionally, we may share information when necessary to fulfill your requests for services or to complete a transaction that you initiate.

              <br /><br />
              <strong>Third-Party Service Providers and Tracking Mechanisms</strong><br />
              We may also allow third-party tracking mechanisms to collect non-personal information for use in online behavioral advertising. This means that third parties may use the fact that you visited the Website to target ads for Guac services to you on non-Guac websites.

              <br /><br />
              <strong>How We Use Anonymized Data</strong><br />
              Guac may make anonymous or aggregate personal information and disclose such data only in a non-personally identifiable manner to:
              <ul className="list-disc ml-6">
                <li>Organizations that conduct research into consumer spending;</li>
                <li>Users of the Service for comparison of their personal financial situation relative to the broader community.</li>
              </ul>

              <br /><br />
              © 2023 New World Savings. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

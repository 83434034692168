import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model";
import { IconPath } from '../../common/icon';

export interface HomeProps {}

export const SupportTemplate: React.FC<HomeProps> = (props: HomeProps) => {
  return (
    <>
      <div className="xl:max-w-7xl mx-auto flex flex-col lg:flex-row relative px-6 py-32 xsm:py-8 sm:py-12 lg:py-24 border-b">
        <div className="div1 w-full md:ml-2 lg:w-2/3 mx-auto max-w-2xl">
          <div className="mb-8 flex justify-left">
            <img
              className="max-w-[50px] lg:w-[100px] text-left"
              src={require('../../assets/Guac_Horizontal_Logo.png')}
              alt="Guac Horizontal Logo"
            />
          </div>
          <div className="w-4/5 text-left">
            <ul className="flex mt-6">
              <li className="max-w-[50px] mr-4">
                <a
                  href="https://www.instagram.com/guacapp.savings/"
                  aria-label="Visit GuacApp on Instagram"
                >
                  <img
                    src={require('../../assets/instagram.svg').default}
                    alt="Instagram logo"
                  />
                </a>
              </li>
              <li className="max-w-[50px] mr-4">
                <a
                  href="https://www.linkedin.com/in/guac-save-while-you-spend-5180b91aa/"
                  aria-label="Visit GuacApp on LinkedIn"
                >
                  <img
                    src={require('../../assets/linkedin.svg').default}
                    alt="LinkedIn logo"
                  />
                </a>
              </li>
              <li className="max-w-[50px] mr-4">
                <a
                  href="https://www.facebook.com/guacapp.savings"
                  aria-label="Visit GuacApp on Facebook"
                >
                  <img
                    src={require('../../assets/facebook.svg').default}
                    alt="Facebook logo"
                  />
                </a>
              </li>
              <li className="max-w-[50px] mr-4">
                <a
                  href="https://twitter.com/guacappsavings"
                  aria-label="Visit GuacApp on Twitter"
                >
                  <img
                    src={require('../../assets/twitter.svg').default}
                    alt="Twitter logo"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="div3 mx-auto max-w-2xl text-center mt-5">
        <div className="flex flex-col lg:flex-row justify-center lg:justify-evenly items-center">
        <p className="mt-6 lg:mt-0 text-sm leading-8 text-gray-600">
            <a target="_blank" href="https://guac-docs.s3.us-west-2.amazonaws.com/Guac_TermsConditions.pdf" aria-label="View Privacy Policy">
                Terms & Conditions
            </a>
          </p>
          <p className="mt-6 lg:mt-0 text-sm leading-8 text-gray-600">
            <a href="/privacy-policy" aria-label="View Privacy Policy">
              Privacy Policy
            </a>
          </p>
          <p className="mt-6 lg:mt-0 text-sm leading-8 text-gray-600">
            <a href="/faq" aria-label="Visit Support Page">
              Support
            </a>
          </p>
          <p className="mt-6 lg:mt-0 text-sm leading-8 text-gray-600">
            <a href="/faq" aria-label="View FAQ">
              FAQ
            </a>
          </p>
        </div>
        <p className="mt-6 text-sm leading-8 text-gray-600">
          All deposits are insured by the Federal Deposit Insurance Corporation (FDIC) up to $250,000 through nbkc bank, Member FDIC.
        </p>
        <p className="mt-6 text-sm leading-8 text-gray-600">
          © 2023 New World Savings, Inc. dba Guac. All Rights Reserved.
        </p>
      </div>
    </>
  );
};

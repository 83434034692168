import React from 'react';

import { CheckIcon } from '@heroicons/react/24/outline'

const Pricing = () => {
  return (
      <>
    </>
  );
};

export default Pricing;

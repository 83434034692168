/* This example requires Tailwind CSS v3.0+ */
import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model"
import { IconPath } from '../../common/icon';
import React from 'react'
import bgImage from '../../assets/bgimagehome.png';

export interface HomeProps {
}

export const OurPhilosophy: React.FC<HomeProps> = (props: HomeProps) => {

    return (
        <></>
    )
}